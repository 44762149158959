import React, {useEffect} from "react";
import config from "../coreFiles/config";
import { Link } from "react-router-dom";
import { Container, Row, Col, Dropdown } from "react-bootstrap";
import { FaXTwitter, FaWhatsapp } from "react-icons/fa6";
import { BiLogoTelegram } from "react-icons/bi";
import "../component/componentCss/footer.css";


const Footer = () => {

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);

  return (
    <>
      <footer className="mt-0 pt-4 pb-4 position-relative overflow-hidden">
        <div className="bg-shape">
          <img alt="" src="assets/images/shape2.svg" className="shape1" />
          <img alt="" src="assets/images/shape2.svg" className="shape2" />
          <img alt="" src="assets/images/shape2.svg" className="shape3" />
          <img alt="" src="assets/images/shape2.svg" className="shape4" />
        </div>

        <Container className="position-relative">
          <Row className="">
            <Col lg={6} md={6} className="">
              <div className="footer-logo">
              <Link to={`${config.baseUrl}`}  onClick={() => {
          window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        }}>
                <img
                  src="assets/images/logo/logo-white.png"
                  alt="footerlogo"
                  className="footerlogo"
                />
                </Link>
                <p>
                  Swiss-protected for privacy and security.
                  <br />
                  That’s Purple Swap.
                </p>
              </div>
            </Col>
            <Col
              lg={6}
              md={6}
              className="text-center "
              style={{ marginBottom: " 1.3rem" }}
            >
              <div className="d-md-flex swift_browser justify-content-end">
                {/* <img
                      src="assets/icons/icons_back.png"
                      className="icons_back"
                    /> */}
                <div className="brousertext">
                  <h5 className="mt-2">
                    Swift and Secure swaps on any devices
                  </h5>

                  <div className="download-browser">
                    <ul>
                      <li>
                        <img alt="" src="assets/images/browser/firefox.svg" />
                      </li>
                      <li>
                        <img alt="" src="assets/images/browser/chrome.svg" />
                      </li>
                      <li>
                        <img alt="" src="assets/images/browser/chromium.svg" />
                      </li>
                      <li>
                        <img alt="" src="assets/images/browser/lion.png" />
                      </li>
                      <li>
                        <img alt="" src="assets/images/browser/playstore.svg" />
                      </li>
                      <li>
                        <img
                          alt=""
                          src="assets/images/browser/apple1.svg"
                          className="apple"
                          style={{ filter: "invert(1)" }}
                        />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <Container className="position-relative">
          <Col lg={12} md={12} className="border-top border-gradient">
            <Row className="footer-menu">
              <Col lg={3} md={4} sm={6} xs={6} className="footer-column">
                <div className="footer-block text-white p-3">
                  <ul className="pl-0 mb-0">
                    <li className="mb-3">
                      <h6 className="text-white mb-0">Exchange</h6>
                    </li>
                    <li className="mb-3">
                      <a href={`${config.baseUrl}btc/usdt`} className="">
                        BTC to USDT
                      </a>
                    </li>
                    <li className="mb-3">
                      <a href={`${config.baseUrl}eth/btc`}>ETH to BTC</a>
                    </li>
                    <li className="">
                      <a href={`${config.baseUrl}sol/xmr`}>SOL to XMR</a>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col lg={3} md={4} sm={6} xs={6} className="footer-column">
                <div className="footer-block text-white p-3">
                  <ul className="pl-0 mb-0">
                    <li className="mb-3">
                      {" "}
                      <h6 className="text-white mb-0">Earning</h6>
                    </li>
                    <li className="mb-3">
                      <a href={`${config.baseUrl}earning/invest`}>Investment</a>
                    </li>
                    <li className="mb-3">
                      <a href={`${config.baseUrl}earning/stake`}>Stake</a>
                    </li>
                    <li className="">
                      <a href={`${config.baseUrl}earning/affiliate`}>Affiliate</a>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col lg={3} md={4} sm={6} xs={6} className="footer-column">
                <div className="footer-block text-white p-3">
                  <ul className="pl-0 mb-0">
                    <li className="mb-3">
                      <h6 className="text-white mb-0">Swap Management</h6>
                    </li>
                    <li className="mb-3">
                      <a href={`${config.baseUrl}swapmanagement/status`}>
                        {" "}
                        Swap Status
                      </a>
                    </li>
                    <li className="mb-3">
                      <a href={`${config.baseUrl}swapmanagement/share`}>
                        Share Swap
                      </a>
                    </li>
                    <li className="">
                      <a href={`${config.baseUrl}swapmanagement/delete`}>
                        Delete Records
                      </a>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col lg={3} md={4} sm={6} xs={6} className="footer-column">
                <div className="footer-block text-white p-3">
                  <ul className="pl-0 mb-0">
                    <li className="mb-3">
                      {" "}
                      <h6 className="text-white mb-0">$PUR</h6>
                    </li>
                    <li className="mb-3">
                      <a href={`${config.baseUrl}pur/launchpad`}>
                        Launchpad
                      </a>
                    </li>
                    <li className="mb-3">
                      <a href={`${config.baseUrl}pur/information`}>
                        Information
                      </a>
                    </li>
                    <li className="">
                      <a href={`${config.baseUrl}pur/roadmap`}>
                        Roadmap
                      </a>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col lg={3} md={4} sm={6} xs={6} className="footer-column">
                <div className="footer-block text-white p-3">
                  <ul className="pl-0 mb-0">
                    <li className="mb-3">
                      <h6 className="text-white mb-0">Support</h6>
                    </li>
                    <li className="mb-3">
                      <a href={`${config.baseUrl}support?t=faq`}>FAQ</a>
                    </li>
                    <li className="mb-3">
                      <a href={`${config.baseUrl}support?t=search`}>Search</a>
                    </li>
                    <li className="">
                      <a href={`${config.baseUrl}support?t=contact`}>
                        Contact Us
                      </a>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Col>
        </Container>
        <Container className="position-relative">
          <Col lg={12} md={12} className="border-top border-gradient">
            <Row className="social-menu align-items-center">
              <Col lg={3} md={3} sm={5} className="">
                <div className="social_icon position-relative">
                  <ul>
                    <li>
                      <a
                        href="https://twitter.com/PurpleSwap_io"
                        target="_blank"
                      >
                        <div className="f-icon">
                          <img
                            src="assets/icons/twitter.png"
                            alt="twitter"
                            className=""
                            width={`15px`}
                          />{" "}
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href="https://t.me/purpleswap" target="_blank">
                        <div className="f-icon">
                          <BiLogoTelegram fill="#fff" width={`15px`} />{" "}
                        </div>
                      </a>
                    </li>
                    {/* <li>
                      <a
                        href="https://www.whatsapp.com/channel/0029VaMeb8i2P59g1S7ijw46"
                        target="_blank"
                      >
                        <div className="f-icon">
                          <FaWhatsapp fill="#fff" width={`15px`} />{" "}
                        </div>
                      </a>
                    </li> */}

                    {/* <li>
                      <a href="javascript:void(0)">
                        <img alt=""
                          src="assets/icons/Twitter.svg"
                          alt="Twitter"
                          className=""
                        />{" "}
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0)">
                        <img alt=""
                          src="assets/icons/Instagram.svg"
                          alt="Instagram"
                          className=""
                        />{" "}
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0)">
                        <img alt=""
                          src="assets/icons/LinkedIn.svg"
                          alt="LinkedIn"
                          className=""
                        />{" "}
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0)">
                        <img alt=""
                          src="assets/icons/YouTube.svg"
                          alt="YouTube"
                          className=""
                        />{" "}
                      </a>
                    </li> */}
                  </ul>
                </div>
              </Col>
              <Col lg={9} md={9} sm={7} className="text-right">
                <div className="d-md-flex align-items-center justify-content-end">
                  <ul className="mb-0 pl-0 d-inline-block list-partner">
                    <li className="d-inline-block me-3 fw-lighter">
                      <span>Trustpilot</span>
                      &nbsp;&nbsp;&nbsp;
                      <span className="rating">
                        <a
                          href="https://www.trustpilot.com/review/purpleswap.io"
                          target="_blank"
                          className="text-decoration-none "
                        >
                          <img
                            src="assets/images/stars-5.svg"
                            width={`100px`}
                          />
                        </a>
                        
                        {/* <a href="javascript:void(0)">1</a>
                      <a href="javascript:void(0)">1</a>
                      <a href="javascript:void(0)">1</a>
                      <a href="javascript:void(0)">1</a> */}
                      </span>
                    </li>
                    <li
                      className="d-inline-block me-3 fw-lighter"
                      style={{ height: "24px" }}
                    >
                      <div>
                        <a target="_blank" href="https://www.bestchange.com/">
                          <img
                            src="assets/images/bestchange.png"
                            className="bestchange"
                          />
                        </a>
                      </div>
                    </li>
                    <li
                      className="d-inline-block me-3 fw-lighter "
                      
                    >
                      <div>
                        <span>
                          <img
                            src="assets/images/swiss-conf.png"
                            className="" width={`36px`}
                          />&nbsp;&nbsp;&nbsp;Incorporated in Switzerland
                        </span>
                      </div>
                    </li>
                    
                    <li className="d-inline-block">
                      <Dropdown className="language-dropdown">
                        <Dropdown.Toggle
                          variant="language"
                          id="dropdown-basic"
                          className="text-white fw-lighter"
                        >
                          <div
                            className="d-inline-flex align-items-center justify-content-between"
                            style={{ width: "100px" }}
                          >
                            <div>English</div>
                            <div className="me-1">
                              <img
                                src="assets/icons/english.png"
                                width="20px"
                              />
                            </div>
                          </div>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item href="javascript:void(0)">
                            Soon
                          </Dropdown.Item>
                          <Dropdown.Item href="javascript:void(0)">
                            Soon
                          </Dropdown.Item>
                          <Dropdown.Item href="javascript:void(0)">
                            Soon
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Col>

          <div className="copyright border-top border-gradient pb-0">
            <Row>
              <Col lg={5} md={5} className="">
                <p className="mb-0">© 2025 Purple Swap. All rights reserved.</p>
              </Col>
              <Col lg={7} md={7} className="text-right">
                <div className="c-list">
                  <a href="https://status.purpleswap.me/" className="" target="_blank">
        
                      <span className="blinking-dot me-2"></span>System Status
                  </a>{" "}
                   &nbsp;-&nbsp;{" "}
                  <a href="http://tinyurl.com/5n6tvuu3"  target="_blank" className="">
                    Press Kit
                  </a>{" "}
                  &nbsp;-&nbsp;{" "}
                  <a href={`${config.baseUrl}legal`}  target="_blank" className="">
                    Legal
                  </a>{" "}
                 {/* &nbsp;-&nbsp;{" "}
                  <a href={config.baseUrl} className="">
                    Terms of Service
                  </a>{" "}
                  &nbsp;-&nbsp;{" "}
                  <a href={config.baseUrl} className="">
                    Terms and Conditions
                  </a> */}
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </footer>
    </>
  );
};

export default Footer;
